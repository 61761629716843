import React from 'react';
import Experience from '../Items/Experience';

const experiencesData = [
  {
    id: 1,
    year: '2023',
    degree: "Master's Degree",
    content:
    'Artificial Intelligence'
  },
  {
    id: 2,
    year: '2023',
    degree: 'Electronic Engineering',
    content:
      'Degree',
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
