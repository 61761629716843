import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const aboutData = {
  cvenpath: 'media/CV_en_FelipePauli_V5.pdf',
  cvsppath: 'media/CV_ptBR_FelipePauli_V5.pdf',
  image: 'images/logo.png',
  name: 'Felipe C. de Pauli',
  location: 'Curitiba, Paraná, Brazil',
  birthday: '16 December, 1983',
  email: 'fcdpauli@gmail.com',
  aboutMe:
    "With an intrinsic passion for technology and a deep-seated inclination towards electronic and computer engineering, I am Felipe C. de Pauli, an enthusiast and dedicated professional in the universe of the Internet of Things (IoT), Big Data, Embedded Systems, and Artificial Intelligence. My journey has been a splendid exploration through the complexities and beauties of Machine Learning, Deep Learning, and Computer Vision, always aiming to unravel new possibilities and create innovative solutions that leverage the capabilities of emerging technologies. Let's talk about all of this or... whatever you want!",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-12">
        <h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            <p className="mb-0">
              Birthday: <span className="text-dark">{aboutData.birthday}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div>
        </div>
        <a
          target={'_blank'}
          href={aboutData.cvenpath}
          className="btn btn-default mr-3"
        >
          <i className="fa-solid fa-cloud-arrow-down"></i>English CV
        </a>
        <div
          className="spacer d-md-none d-lg-none d-sm-none"
          data-height="10"
        ></div>

        <a
          target={'_blank'}
          href={aboutData.cvsppath}
          className="btn btn-default mr-3"
        >
          <i className="fa-solid fa-cloud-arrow-down"></i>CV Português
        </a>

        <div
          className="spacer d-md-none d-lg-none d-sm-none"
          data-height="10"
        ></div>

        <ScrollLink
          activeClass="active"
          to="section-contact"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-alt mt-md-0"
        >
          <i className="fa-solid fa-paper-plane"></i>Contact me
        </ScrollLink>
      </div>
    </div>
  );
}

export default About;
